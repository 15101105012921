import React from 'react'
import '../assets/css/About.styl'
function About() {
        return (
            <div className="about flex-justify-center flex-column">
               <div className="banner flex-items-center flex-justify-center bold">
               </div>
               <div className="flex-column flex-items-center">
                    <div className="about-title fs-36 mt-36 bold">
                        <div className="tit">
                            关于我们
                        </div>
                    </div>
                    <div className="about-content fs-24 text-center">
                        <div>
                        武汉晴空蓝翼网络科技有限公司
                        </div>
                        <div className="mt-30">
                        联系电话：17801139312（周一至周日，10:00-18:00）
                        </div>
                        <div className="mt-30">
                        联系地址：武汉东湖新技术开发区关山大道111号武汉光谷国际商务中心A栋18层01、02、03、04、10、11室A1364
                        </div>
                        <div className="mt-30">
                        
                        </div>
                    </div>
               </div>
            </div>
        );
    }
export default About;