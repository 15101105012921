import React from 'react'
import '../assets/css/Product.styl'
function Product() {
        return (
            <div className="product">


                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_8.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo8.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        安心充电助手(快应用)
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        安心充电助手是一款功能强大的电动汽车生活助手应用，为电动汽车用户提供了充电桩列表、实时天气和电动汽车使用指南等多项实用功能。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/axcdzs_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="axcdzs_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo7.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        晴空手机查找器
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        强大的声音引擎识别您的呼叫，使手机发出声光、震动，帮您找到遗失在附近的手机。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/qksjczq/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="sjczq/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_7.png")} alt="" width="324" height="514"/>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_6.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo6.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        霏雨天气通(快应用)
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        霏雨天气通为您提供全国天气查询，还有空气质量等信息，为您每次出行提供建议
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/fytqt_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="fytqt_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo5.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        健康美食家（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        健康美食家为您带来海量的各式美食菜谱，无论是家常菜还是硬菜大餐都能轻松拿捏。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/jkmsj_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="jkmsj_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_5.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_4.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo4.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        羽翼壁纸大全(快应用)
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        各种主题高清壁纸、动态壁纸集于一身，一键设置个性化壁纸，随心所欲设置来电秀；收集了当下热门金曲，可设置自己独特的专属个性铃声。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/yybzdq_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="yybzdq_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo3.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        安心充电助手
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        安心充电助手是您电动汽车充电的有力帮手，您可方便查询全国充电桩信息，同时还为您提供全国天气信息，方便您的出行。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/axcdzs/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="axcdzs/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_3.png")} alt="" width="324" height="514"/>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_1.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo1.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        奇缘运势(快应用)
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        专业解析每日星座运势，带你了解12星座的日、周、年运势变化，事业发展、身体健康以及情感变动等。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/qyys_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="qyys_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                </div>

               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo2.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        妙趣字谜（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        妙趣字谜为您带来丰富的成语字谜，让您在潜移默化中了解中国浩大广博的成语知识，在游玩中不断锻炼自己的解谜能力。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/mqzm_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="mqzm_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_2.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               

            </div>
        );
    }
export default Product;
