import React from 'react'
import '../assets/css/Footer.styl'
function Footer() {
        return (
            <div className="footer  flex-column flex-items-center flex-justify-center">
               <div className="fs-16">© 武汉晴空蓝翼网络科技有限公司 版权所有</div>
               <div className="fs-16 mt-16"><a className="mt-17" href='https://beian.miit.gov.cn/#/Integrated/recordQuery' target='_blank'>鄂ICP备2022008341号-1</a></div>
            </div>
        );
    }
export default Footer;